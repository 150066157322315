import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { Container, Heading, Box, Grid } from "@theme-ui/components"
import Layout from "../components/layout"
import { getHomePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import ContactForm from "../components/blocks/contactForm"
import ImageAndText from "../components/blocks/imageAndText"
import Embed from "../components/blocks/embed"
import { GatsbyImage } from "gatsby-plugin-image"
import RichContentStructuredText from "../components/richContentStructuredText"
import ItemCarousel from "../components/blocks/itemCarousel"
import GooeyShapes from "../components/gooeyShapes"
import { ArrowDownCircle } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"

const Home = ({ data: { page, site } }) => {
  const favicon = useFavicon().site.faviconMetaTags
  const secondary = getColor(themeUiTheme, "secondary")
  const index = useBreakpointIndex();

  const i18nPaths = site.locales.map(locale => {
    return {
      locale: locale,
      value: getHomePath(locale),
    }
  })

  var blockIds = page.content.map(block => {
    return {
      id: block.id,
      title: block.content ? block.content.kicker : block.kicker,
    }
  })

  return (
    <Layout locale={page.locale} pageSections={blockIds} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>

      <Box
        sx={{
          top: [-8],
          position: "absolute",
          left: [-8],
          width: "22vw",
          height: "22vw",
        }}
      >
        <GooeyShapes gooeytype="top_left_header" />
      </Box>

      <Box sx={{ position: "relative" }}>
        <Container sx={{ position: "relative", zIndex: 1 }}>
          <Grid columns={["1fr", "1fr", ".5fr .5fr"]} gap={[0, 0, 0, 7]}>
            <Box sx={{ width: "100%", py: [8,9, 11], pb: [5,5,5, 11], zIndex: 2 }}>
              <Box>
                <Heading as="h1" variant="h1">
                  <Box as="span" sx={{ letterSpacing: "-5px" }}>
                    ___
                  </Box>{" "}
                  {page.title}
                </Heading>
              </Box>
              <Box sx={{ pr: [5] }}>
                <RichContentStructuredText text={page.body} />
              </Box>
              <Box
                sx={{
                  pt: [3, 4, 8],
                  svg: {
                    strokeWidth: "1px",
                  },
                }}
              >
                <ArrowDownCircle size={64} color={secondary} />
              </Box>
            </Box>
          </Grid>
        </Container>
        <Grid
          columns={["1fr", "1fr", "1fr 1fr"]}
          gap={[0]}
          sx={{
            position: ["relative", "relative", "absolute"],
            top: 0,
            zIndex: 0,
            left: 0,
            width: "100%",
            height: "100%",
            gridTemplateRows: [
              "minmax(400px,1fr)",
              "minmax(400px,1fr)",
              "1fr",
            ],
          }}
        >
          
          <Box
            sx={{ display:["none" , "none", "block"] ,width: "100%", py: [8, 11], pb: [5, 11], zIndex: 2 }}
          ></Box>
          <Box sx={{ height: "100%", width: "100%", zIndex: 1 }}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                position: "relative",
                ".gatsby-image-wrapper": {
                  height: ["100%","100%","60%"],
                  width: ["100%"],
                  position: "absolute",
                  top: "50%",
                  right: ["unset","unset",-5],
                  transform: ["translateY(-50%)","translateY(-50%)","translateY(-50%)"],
                  img: {
                    margin:"0 auto",
                    height: ["100%","100%","80%"],
                    width: ["100%","100%","80%"],
                    objectFit: "contain!important",
                  },
                },
              }}
            >
              <GooeyShapes gooeytype="header" />
              <GatsbyImage image={page.heroImage.gatsbyImageData} alt="" />
            </Box>
          </Box>
        </Grid>
        <Box
          sx={{
            display: ["none", "none", "none", "block"],
            width: "500px",
            maxHeight: ["500px", "500px", "500px", "500px"],
            height: ["100%"],
            top: "80%",
            transform: "translateY(-50%)",
            position: "absolute",
            left: [-6],
          }}
        >
          <GooeyShapes gooeytype="bottom_left_header" />
        </Box>
      </Box>

      {page.content.map(
        block =>
          block.model &&
          block.model.apiKey && (
            <Box as="section" id={block.id} key={block.id}>
              {block.model.apiKey === "contact_form" && (
                <ContactForm
                  title={block.title}
                  kicker={block.kicker}
                  subtitle={block.subtitle}
                  description={block.description}
                  image={block.image}
                />
              )}
              {block.model.apiKey === "embed" && (
                <Embed
                  kicker={block.kicker}
                  code={block.code}
                  title={block.title}
                  fullWidth={block.fullWidth}
                />
              )}
              {block.model.apiKey === "image_and_text" && (
                <ImageAndText
                  kicker={block.content.kicker}
                  subtitle={block.content.subtitle}
                  title={block.content.title}
                  body={block.content.body}
                  image={block.content.image}
                  alignText={block.alignText}
                  animationStyle={block.animationStyle}
                />
              )}
              {block.model.apiKey === "item_carousel" && (
                <ItemCarousel items={block.items} />
              )}
            </Box>
          )
      )}
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery($id: String!) {
    page: datoCmsHomePage(id: { eq: $id }) {
      id
      title
      locale
      heroImage {
        gatsbyImageData(
          width: 1480
          height: 986
          placeholder: BLURRED
          forceBlurhash: false
        )
      }
      body {
        value
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsItemCarousel {
          id
          kicker
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          alignText
          animationStyle
          model {
            apiKey
          }
        }
        ... on DatoCmsEmbed {
          id
          ...EmbedDetails
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          image {
            url
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
      }
    }
    site: datoCmsSite {
      locales
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    kicker
    subtitle
    image {
      url
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      value
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
        }
      }
      value
    }

    model {
      apiKey
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    kicker
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment ImageGallery on DatoCmsImageGallery {
    images {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
      alt
      title
    }
    model {
      apiKey
    }
  }
`
