import React from "react"
import { Box, Container, Text, Heading } from "@theme-ui/components"

const Embed = ({ kicker, title, code, fullWidth }) => {
  const Wrapper = fullWidth ? Box : Container
  return (
    <Wrapper mb={5} sx={{ py:[4,4,10]}}>
      <Text variant="kicker">{kicker}</Text>
      <Heading variant="h2" as="h2" sx={{ mb: [5], mt: [0] }}>
        {title}
      </Heading>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "0",
          paddingBottom: "56.25%",
          iframe: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          },
        }}
        dangerouslySetInnerHTML={{ __html: code }}
      />
    </Wrapper>
  )
}

export default Embed
