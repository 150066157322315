import React from "react"
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Image,
  Container,
} from "@theme-ui/components"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"

// Begin swiper
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import RichContentStructuredText from "../richContentStructuredText"
SwiperCore.use([Mousewheel, Pagination, A11y])
// End swiper

const ItemCarousel = ({ items }) => {
  const primary = getColor(themeUiTheme, "primary")
  const yellow = getColor(themeUiTheme, "customYellow")

  return (
    <Box
      sx={{
        background: [
          `unset`,
          `unset`,
          `linear-gradient(90deg, ${primary} 80%, ${yellow} 40%)`,
          `linear-gradient(90deg, ${primary} 45%, ${yellow} 40%)`,
        ],
      }}
    >
      <Container sx={{ py: [0, 0, 0] ,px:[0,0,0,4] }}>
        <Grid columns={[1,1, 2]} gap={[0]} >
          {items.map((item, index) => (
            <Item richContent={item} index={index} />
          ))}
        </Grid>
      </Container>
    </Box>
  )
} 

const Item = ({ richContent, index }) => (
  <Box
    sx={{
      backgroundColor: index % 2 == 0 ? "primary" : "customYellow",
      py: [8],
      pl: index == 1 && [0,0,8, 9],
      pr: index == 0 && [0,0,8, 9],
       px: [3,4]
    }}
  >
    <Flex
      sx={{
        justifyContent: "space-between",
        height: "max-content",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "60%" }}>
        <Heading
          as="h2"
          variant="h2"
          sx={{ color: index === 0 ? "customYellow" : "primary", lineHeight: "1.094", fontSize: [6, 7], my: 0 }}
        >
          {richContent.title}
        </Heading>
      </Box>
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          width: "40%",
          img: { color: index === 1 ? "customYellow" : "primary", width: "100%", height: "125px" },
        }}
      >
        <Image src={richContent.image.url} alt="" />
      </Box>
    </Flex>
    <Box>
      <Heading variant="h3" sx={{ mt: 0 }}>
        {richContent.subtitle}
      </Heading>
      <Box sx={{ mt: [6], p: { color: index === 0 ? "customYellow" : "primary", lineHeight:"1.25" } }}>
        <RichContentStructuredText text={richContent.body} theme="light" />
      </Box>
    </Box>
  </Box>
)

export default ItemCarousel
