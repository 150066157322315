import React, { useState, useEffect } from "react"
import {
  Box,
  Container,
  Grid,
  Heading,
  Flex,
  Image,
  Text,
  Button,
} from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { motion } from "framer-motion"
import RichContentStructuredText from "../richContentStructuredText"
import * as Scroll from "react-scroll"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"
import GooeyShapes from "../../components/gooeyShapes"

const TextAndImageGallery = ({
  kicker,
  subtitle,
  title,
  body,
  image,
  alignText,
  animationStyle,
}) => {
  const LinkScroll = Scroll.Link
  const index = useBreakpointIndex()
  const primary = getColor(themeUiTheme, "primary")

  return (
    <Box
      sx={{
        zIndex: 3,
        position: "relative",
        backgroundColor:
          (animationStyle == "land_phone" || !animationStyle) && "primary",
        height: "100%",

        pb: [0, 0],
      }}
    >
      <Container
        variant={"custom"}
        sx={{ pt: [3, 6], zIndex: 1, position: "relative" }}
      >
        <Grid
          columns={["1fr", "1fr", "1fr 1fr"]}
          gap={[0, 0, 7]}
          sx={{
            position: "relative",
            height: "100%",
            width: "100%",
            gridTemplateRows: ["1fr"],
          }}
        >
          <Flex
            sx={{
              width: "100%",
              m: "0 auto",
              height: "100%",
              flexDirection: "column",
              justifyContent: "right",
              alignItems: "start",
              py: [4, 9],
              gridRow: [2, 2, 2, 1],
              gridColumn: [1, 1, 1, alignText === "right" ? 2 : 1],
            }}
          >
            <Text variant="kicker">{kicker}</Text>
            <Heading
              variant="h2"
              sx={{
                mt: 0,
                mb: [4, 5],
                fontSize: [5, 6],
                color: "primary",
              }}
            >
              {title}
            </Heading>
            <RichContentStructuredText text={body} />
          </Flex>
          <Box
            sx={{
              gridColumn: [1, 1, 1, alignText === "right" ? 1 : 2],
              display: ["none", "none", "block"],
            }}
          ></Box>
        </Grid>
      </Container>
      <Grid
        columns={["1fr", "1fr",  "1fr 1fr"]}
        gap={[0, 0, 0]}
        sx={{
          zIndex: 0,
          position: ["relative", "relative",  "absolute"],
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          gridTemplateRows: [
            "minmax(400px,1fr)",
            "minmax(500px,1fr)",
            "minmax(600px,1fr)",
            "minmax(800px,1fr)",
          ],
        }}
      >
        <Box  
          sx={{
            gridColumn: [1, 1, 1, alignText === "right" ? 2 : 1],
            display: ["none", "none",  "block"],
          }}
        >
          {animationStyle === "chi_siamo" && (
            <GooeyShapes gooeytype="chi_siamo_decoration" />
          )}
          {animationStyle === "cosa_facciamo" && (
            <GooeyShapes gooeytype="cosa_facciamo_decoration" />
          )}
        </Box>
        <Box
          sx={{
            gridColumn: [1, 1, 1, alignText === "right" ? 1 : 2],
            gridRow: "1",
            height: "100%",
            width: "100%",
            zIndex: 1,
            position: "relative",
            overflow:"hidden",
          }}
        >
          {animationStyle === "chi_siamo" && (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                
                position: "relative",
                overflow:"hidden",
                ".gatsby-image-wrapper": {
                  height: "100%",
                  maxHeight: ["500px", "500px", "500px", "800px"],
                  width: "auto",
                  position: "relative",
                  right:[-4,-4,"unset"],
                  img: {
                    mt:[4,4,0],
                    width:["90%","90%","100%"],
                    height:["90%","90%","100%"],
                    objectFit: "contain!important",
                  },
                },
              }}
            >
              <GooeyShapes gooeytype="chi_siamo" />
              <GatsbyImage image={image.gatsbyImageData} alt="" />
            </Box>
          )}
          {animationStyle === "cosa_facciamo" && (
            <Box
              sx={{
                height: "100%",
                width: "100%",
                zIndex: 1,
                position: "relative",
              }}
            >
              <GooeyShapes gooeytype="cosa_facciamo" />
            </Box>
          )}
          {animationStyle === "A_chi_ci_rivolgiamo" && (
            <>
              <GooeyShapes gooeytype="a_chi_ci_rivolgiamo" />

              <Box
                sx={{
                  zIndex: 1,
                  height: "100%",
                  width: "100%",
                  position: "relative",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  ".gatsby-image-wrapper": {
                    height: "100%",
                    maxHeight: ["600px", "600px", "600px", "800px"],
                    width: "auto",
                    position: "relative",
                    img: {
                      objectFit: "contain!important",
                    },
                  },
                }}
              >
                <GatsbyImage image={image.gatsbyImageData} alt="" />
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </Box>
  )
}

export default TextAndImageGallery
