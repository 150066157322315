import React, { useState } from "react"
import {
  Box,
  Container,
  Text,
  Heading,
  Grid,
  Input,
  Button,
  Image,
  Flex,
  Label,
  Link,
} from "@theme-ui/components"
import { ArrowRight } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { Textarea, Checkbox } from "theme-ui"
import axios from "axios"
import { InboundLink, OutboundLink } from "../link"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import GooeyShapes from "../../components/gooeyShapes"

const ContactForm = ({ title, kicker, description, image }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const dark = getColor(themeUiTheme, "dark")
  const isBrowser = typeof window !== "undefined"

  const [formData, setFormData] = useState({
    email: "",
    nomeCognome: "",
    messaggio: "",
    accordoPrivacyCookies: false,
    accordoNewsLetter: false,
    urlPagina: isBrowser && window.location.href,
  })

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const sendMail = async event => {
    event.preventDefault()

    const result = await executeRecaptcha("dynamicAction")

    setLoading(true)
    const data = formData

    if (result)
      axios
        .post("/.netlify/functions/sendMail", data)
        .then(function (response) {
          setSuccess(true)
          setLoading(false)

          if (typeof window !== "undefined" && window.dataLayer !== undefined) {
            window.dataLayer = window.dataLayer || []

            window.dataLayer.push({
              event: "formSubmission",
              formType: "Contact",
            })
          }
        })
        .catch(function (error) {
          console.log("error", error)
          setLoading(false)
        })
    else setLoading(false)
  }

  return (
    <Box sx={{ py: [3, 4], pb: [4, 7], position: "relative" }}>
      <Box sx={{ zIndex: 0 }}>
        <GooeyShapes gooeytype="contatti_decorration" />
      </Box>
      <Container
        name="contactForm"
        sx={{ py: [0, 0, 0], zIndex: 1, position: "relative" }}
      >
        <Grid
          columns={["1fr", "1fr", "1fr 1fr"]}
          gap={[1, 1, 1, 6]}
          sx={{
            pb: [3, 4, 4, 4],
            gridTemplateRows: ["1fr"],
          }}
        >
          <Box
            sx={{
              position: "relative",
              height: "100%",
              width: "100%",
              gridRow: [2, 2, 2, 1],
              zIndex: 1,
            }}
          >
            <Text variant="kicker">{kicker}</Text>
            <Heading
              as="div"
              variant="sectionTitle"
              sx={{
                color: "primary",
                fontSize: [6],
                fontWeight: "body",
                letterSpacing: "-.5px",
                lineHeight: "1.12",
                em: {
                  fontStyle: "normal",
                  color: "primary",
                },
              }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            {!success ? (
              <Box
                as="form"
                id="contactForm"
                onSubmit={sendMail}
                sx={{ my: [4] }}
              >
                <Box>
                  <Grid
                    sx={{
                      flexWrap: "wrap",
                      flexDirection: "column",
                    }}
                    gap={[0, 0, 0, 0]}
                  >
                    <Flex sx={{ flexWrap: "wrap" }}>
                      <Box
                        sx={{
                          pr: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              nomeCognome: e.target.value,
                            }))
                          }
                          name="nome cognome"
                          placeholder="Nome e Cognome*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                      <Box
                        sx={{
                          pr: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              email: e.target.value,
                            }))
                          }
                          name="email"
                          type="email"
                          placeholder="Email*"
                          variant={"inputs.primary"}
                          required
                        />
                      </Box>
                    </Flex>

                    <Textarea
                      sx={{
                        fontFamily: "body",
                        fontSize: [2],
                      }}
                      onChange={e =>
                        setFormData(prevState => ({
                          ...prevState,
                          messaggio: e.target.value,
                        }))
                      }
                      name="messaggio"
                      placeholder="Messaggio"
                      variant={"inputs.primary"}
                      type="textarea"
                    />
                    <LabeledCheckbox
                      checkedCheckbox={e => {
                        setFormData(prevState => ({
                          ...prevState,
                          accordoPrivacyCookies: e,
                        }))
                      }}
                      required={true}
                    >
                      <Box>
                        <Text
                          variant="p"
                          sx={{
                            fontSize: [0, 0],
                            letterSpacing: "0px",
                            color: "dark",
                          }}
                        >
                          * Acconsento a che i miei dati personali vengano
                          utilizzati in accordo con la
                          <OutboundLink
                            sx={{
                              color: "dark",
                              textDecoration: "underline",
                            }}
                            href="https://www.iubenda.com/privacy-policy/63021092"
                            target="_blank"
                          >
                            {" "}
                            privacy
                          </OutboundLink>{" "}
                          e{" "}
                          <OutboundLink
                            sx={{
                              color: "dark",
                              textDecoration: "underline",
                            }}
                            href="https://www.iubenda.com/privacy-policy/63021092/cookie-policy"
                            target="_blank"
                          >
                            cookie
                          </OutboundLink>{" "}
                          policy
                        </Text>
                      </Box>
                    </LabeledCheckbox>
                    {console.log(
                      "accordoNewsLetter",
                      formData.accordoNewsLetter
                    )}
                    <LabeledCheckbox
                      checkedCheckbox={e =>
                        setFormData(prevState => ({
                          ...prevState,
                          accordoNewsLetter: e,
                        }))
                      }
                    >
                      <Box>
                        <Text
                          variant="p"
                          sx={{
                            fontSize: [0, 0],
                            letterSpacing: "0px",
                            lineHeight: "1.4",
                          }}
                        >
                          Acconsento all’uso dei miei dati personali per essere
                          aggiornato sui nuovi arrivi, prodotti in esclusiva e
                          per le finalità di marketing diretto correlate ai
                          servizi offerti e ricevere proposte in linea con i
                          miei interessi attraverso l’analisi dei miei
                          precedenti acquisti
                        </Text>
                      </Box>
                    </LabeledCheckbox>
                    <Box
                      sx={{
                        mt: [3, 4],
                      }}
                    >
                      <Button
                        type="submit"
                        title={
                          !formData.accordoPrivacyCookies
                            ? "Acconsenti al trattamenti dei dati personali"
                            : ""
                        }
                        sx={{
                          opacity: !formData.accordoPrivacyCookies && 0.7,
                          mt: [3, 3, 3, 3],
                          px: [4, 6],
                          py: [2, 3],
                          borderRadius: "0",
                        }}
                        variant="buttons.full.primaryForm"
                      >
                        <Flex
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {loading ? "Caricamento..." : "Invia richiesta"}
                        </Flex>
                      </Button>
                    </Box>
                  </Grid>
                </Box>
              </Box>
            ) : (
              <Box sx={{  }}>
                <Heading
                  sx={{
                    my: [4],
                    color: "primary",
                    fontSize: [6, 7],
                  }}
                  as="h4"
                >
                  Richiesta inviata!
                </Heading>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "100%",
              width: "100%",
              top: [-8],
              minHeight: ["200px", "", "auto"],
              zIndex: 0,
              gridRow: [1, 1, 1],
            }}
          >
            <Image
              sx={{
                position: "absolute",
                height: "100%",
                width: "100%",
                minHeight: ["300px", "400px", "auto"],
              }}
              src={image.url}
            />
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

const LabeledCheckbox = ({
  children,
  defaultChecked,
  contactPage,
  checkedCheckbox,
  ...props
}) => {
  const [checked, setChecked] = useState(defaultChecked)

  return (
    <Label
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: checked ? "bold" : "normal",
        color: "dark",
        "input:checked~.css-kydphz": {
          color: "light",
        },
        svg: {
          color: "dark",
        },
        mt: [3, 3, 3, 3],
      }}
    >
      <Checkbox
        sx={{
          color: "dark",
          "input:checked~&": {
            color: "dark",
          },
        }}
        checked={checked}
        onChange={() => {
          checkedCheckbox(!checked)
          setChecked(!checked)
        }}
        {...props}
      />
      {children}
    </Label>
  )
}

export default ContactForm
