import React from "react"
import { Box, Text } from "@theme-ui/components"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  isList,
  renderRule,
} from "datocms-structured-text-utils"
import ImageGallery from "./blocks/imageGallery"
import { MagicLink } from "../utils/magicLink"
import NumbersGroup from "./blocks/numbersGroup"
import Embed from "./blocks/embed"
import ContactForm from "../components/blocks/contactForm"

const RichContentStructuredText = ({ text, theme }) => {
  const componentTheme = theme || "light"
  const light = componentTheme === "light" ? "light" : "dark"
  const dark = componentTheme === "light" ? "dark" : "light"

  return (
    <Box>
      {text.value && (
        <StructuredText
          data={text}
          renderLinkToRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsInternalLink":
                return <MagicLink item={record} lcoale={record.locale} />
              default:
                return null
            }
          }}
          renderInlineRecord={({ record }) => {
            switch (record.__typename) {
              case "DatoCmsInternalLink":
                return (
                  <Box>
                    {<MagicLink item={record} lcoale={record.locale} />}
                  </Box>
                )
              default:
                return null
            }
          }}
          renderBlock={({ record }) => {
            // console.log(record)
            switch (record.__typename) {
              case "DatoCmsContactForm":
                return (
                  <ContactForm
                    title={record.title}
                    kicker={record.kicker}
                    subtitle={record.subtitle}
                    description={record.newsletterDescription}
                  />
                )
              case "DatoCmsImageGallery":
                return (
                  <Box mt={5} mb={5}>
                    <ImageGallery images={record.images} key={record.id} />
                  </Box>
                )
              case "DatoCmsNumbersGroup":
                return (
                  <Box mt={5} mb={5}>
                    <NumbersGroup numbers={record.numbers} key={record.id} />
                  </Box>
                )
              case "DatoCmsEmbed":
                return <Embed code={record.code} fullWidth={record.fullWidth} />
              default:
                return null
            }
          }}
          customRules={[
            renderRule(
              isHeading,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text
                        as={`h${node.level}`}
                        variant={`h${node.level}`}
                        color={"primary"}
                      >
                        {children}
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
            renderRule(
              isParagraph,
              ({ adapter: { renderNode }, node, children, key }) => {
                return renderNode(
                  () => {
                    return (
                      <Text as="p" mb={3} color={dark}>
                        {children}
                      </Text>
                    )
                  },
                  { key },
                  children
                )
              }
            ),
          ]}
        />
      )}
    </Box>
  )
}

export default RichContentStructuredText
